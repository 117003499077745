import { ref, computed } from 'vue'
import axios from 'axios'
import router from './router'

/**
 * State
 */
const state = ref({
  loading: false,
  checkoutOpen: false,
  error: null,
  issuers: [],
  data: null,
  isPaid: false,
})

export const isLoading = computed(() => state.value.loading)
export const isCheckoutOpen = computed(() => state.value.checkoutOpen)
export const error = computed(() => state.value.error)
export const getIssuers = computed(() => state.value.issuers)
export const isPaid = computed(() => state.value.isPaid)

export const setCheckoutData = data => (state.value.data = data)
export const setIssuerInData = issuer => {
  state.value.data.method = issuer.method
  state.value.data.issuer = issuer.id
}
export const setCheckoutOpen = (value = true) =>
  (state.value.checkoutOpen = value)

/**
 * Actions
 */
export const fetchIssuers = async () => {
  const res = await axios('/api/v1/website/urls/payment/issuers')
  state.value.issuers = res.data
}

export const submitCheckout = async () => {
  state.value.error = null
  state.value.loading = true
  try {
    const res = await axios.post(
      '/api/v1/website/urls/checkout',
      state.value.data
    )
    state.value.loading = false
    if (res.data.error) {
      state.value.error = res.data.error
      return
    }
    if (res.data.checkout_url) {
      window.location = res.data.checkout_url
      return
    }
    router.push({ name: 'Completed' })
  } catch (e) {
    state.value.loading = false
    state.value.error = 'invalid'
  }
}

export const fetchStatus = async payment => {
  try {
    const res = await axios(`/api/v1/website/urls/payment/${payment}/status`)
    console.log(res.data.is_paid)
    state.value.isPaid = res.data.is_paid
  } catch (e) {
    state.value.isPaid = false
  }
}
