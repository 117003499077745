<template>
  <transition name="fade">
    <div v-if="isCheckoutOpen" class="checkout-modal">
      <div class="checkout-modal-overlay" @click="close"></div>
      <div class="checkout-modal-body">
        <header>
          <h2>{{ contents.checkout.title }}</h2>
          <p class="method">iDeal</p>
        </header>
        <ul>
          <li
            v-for="issuer in issuers"
            :key="issuer.id"
            @click="selectedIssuer = issuer"
            :class="{ selected: selectedIssuer?.id === issuer.id }"
          >
            <img
              :src="issuer.image.normal"
              :srcset="`${issuer.image.normal} 1x, ${issuer.image.bigger} 2x`"
              :alt="issuer.name"
            />
            <p>{{ issuer.name }}</p>
          </li>
        </ul>
        <p class="form-field__error" v-if="error">
          {{ contents.checkout.error }}
        </p>
        <div class="checkout-modal-body__footer">
          <p class="checkout-modal-body__footer-price">
            <s>€ {{ oldTotalCost }}</s>
            € {{ totalCost }}
          </p>
          <button
            :disabled="!selectedIssuer"
            class="button button-success"
            @click="submit"
          >
            {{ contents.checkout.buttons.pay }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'
import {
  isCheckoutOpen,
  fetchIssuers,
  getIssuers,
  error,
  setIssuerInData,
  setCheckoutOpen,
  submitCheckout,
} from '@/repository'
import { formatMarkup } from '@/utils.js'

import contents from '../assets/contents.json'

export default {
  name: 'Checkout',
  props: {
    totalCost: String,
    oldTotalCost: String,
  },
  setup: () => {
    const selectedIssuer = ref(null)

    fetchIssuers()

    const submit = () => {
      setIssuerInData(selectedIssuer.value)
      submitCheckout()
    }

    const close = () => {
      setCheckoutOpen(false)
    }

    return {
      issuers: getIssuers,
      isCheckoutOpen,
      selectedIssuer,
      contents,
      error,

      submit,
      close,
      formatMarkup,
    }
  },
}
</script>
