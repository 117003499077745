export const formatDate = date => {
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '-' +
    date.getFullYear()
  )
}

export const formatMarkup = string => {
  return string.replace(/(^|[^~])~(?!~)((?:[^]*?[^~])?)~(?!~)/g, '$1<s>$2</s>')
}
