<template>
  <div class="general container">
    <Branding />
    <main class="content-main">
      <div>
        <header class="content-header">
          <h1>{{ contents.title }}</h1>
          <p>{{ contents.body }}</p>
        </header>
        <Form
          class="content-form"
          @submit="onSubmit"
          :validation-schema="schema"
        >
          <div class="content-form-fields">
            <div class="content-form__row">
              <div class="content-form__row-child">
                <div class="form-field">
                  <label for="firstname">{{ contents.labels.firstname }}</label>
                  <Field
                    type="text"
                    name="firstname"
                    :value="prefilled.firstname"
                  />
                </div>
                <ErrorMessage class="form-field__error" name="firstname" />
              </div>
              <div class="content-form__row-child">
                <div class="form-field">
                  <label for="lastname">{{ contents.labels.lastname }}</label>
                  <Field
                    type="text"
                    name="lastname"
                    :value="prefilled.lastname"
                  />
                </div>
                <ErrorMessage class="form-field__error" name="lastname" />
              </div>
            </div>

            <div class="content-form__row">
              <div class="content-form__row-child">
                <div class="form-field">
                  <label for="email">{{ contents.labels.email }}</label>
                  <Field type="text" name="email" :value="prefilled.email" />
                </div>
                <ErrorMessage class="form-field__error" name="email" />
              </div>

              <div class="content-form__row-child">
                <div class="form-field">
                  <label for="quantity">{{ contents.labels.quantity }}</label>
                  <Field
                    type="number"
                    min="1"
                    max="4"
                    name="quantity"
                    v-model="quantity"
                  />
                </div>
                <ErrorMessage class="form-field__error" name="quantity" />
              </div>
            </div>

            <DatePicker
              isRange
              v-model="range"
              :columns="2"
              :masks="{ input: 'DD-MM-YYYY' }"
              :minDate="new Date()"
              class="content-form__row"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="content-form__row-child">
                  <div class="form-field">
                    <label>{{ contents.labels.arrival }}</label>
                    <input :value="inputValue.start" v-on="inputEvents.start" />
                  </div>
                  <p v-if="rangeFeedback" class="form-field__error">
                    {{ rangeFeedback }}
                  </p>
                </div>
                <div class="content-form__row-child">
                  <div class="form-field">
                    <label>{{ contents.labels.departure }}</label>
                    <input :value="inputValue.end" v-on="inputEvents.end" />
                  </div>
                </div>
              </template>
            </DatePicker>

            <div class="content-form__row" v-if="hasRefNumber">
              <div class="content-form__row-child">
                <div class="form-field">
                  <label for="email">{{ contents.labels.ref_number }}</label>
                  <Field type="text" name="ref_number" />
                </div>
                <p v-if="refNumberFeedback" class="form-field__error">
                  {{ refNumberFeedback }}
                </p>
              </div>
            </div>

            <p v-if="errorFeedback" class="form-field__error">
              {{ errorFeedback }}
            </p>
          </div>

          <div class="content-form-footer">
            <p class="content-form-footer__price">
              {{ contents.price_text }}
              <s>€ {{ oldTotalCost }}</s>
              € {{ totalCost }}
            </p>
            <div class="content-form-footer__actions">
              <button class="button button-success" type="submit">
                {{ contents.buttons.order }}
              </button>
            </div>
          </div>
        </Form>
      </div>
      <img
        class="content-mockup"
        src="@/assets/images/app-mockup.png"
        srcset="
          @/assets/images/app-mockup.png,
          @/assets/images/app-mockup-2x.png 2x
        "
      />
    </main>
  </div>
  <Checkout :totalCost="totalCost" :oldTotalCost="oldTotalCost" />
</template>

<script>
import { computed, ref } from 'vue'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { DatePicker } from 'v-calendar'
import { useRoute } from 'vue-router'
import {
  submitCheckout,
  setCheckoutData,
  setCheckoutOpen,
  error,
} from '@/repository.js'
import { formatDate, formatMarkup } from '@/utils.js'

import Checkout from '@/components/Checkout'
import Branding from '@/components/Branding'

import contents from '../assets/contents.json'
import customs from '../assets/customs.json'

export default {
  name: 'General',
  components: {
    Form,
    Field,
    ErrorMessage,
    DatePicker,
    Checkout,
    Branding,
  },
  setup: () => {
    const route = useRoute()

    const schema = yup.object({
      firstname: yup
        .string()
        .required()
        .max(200),
      lastname: yup
        .string()
        .required()
        .max(200),
      email: yup
        .string()
        .required()
        .email(),
      quantity: yup
        .number()
        .required()
        .integer()
        .min(1)
        .max(4),
    })

    const quantity = ref(1)
    const range = ref({ start: null, end: null })
    const rangeFeedback = ref()
    const refNumberFeedback = ref()

    const { fn, ln, m, start, end, sid, token } = route.query
    if (start && end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      console.log(startDate, endDate, endDate)
      if (startDate && endDate) {
        range.value.start = startDate
        range.value.end = endDate
      }
    }
    const prefilled = {
      firstname: fn ? fn : '',
      lastname: ln ? ln : '',
      email: m ? m : '',
    }

    const hasRefNumber = computed(() => {
      const id = Number(sid)
      let state = false
      for (const custom of customs) {
        if (custom.ids.includes(id)) {
          state = custom.ref_number
          break
        }
      }
      return state
    })

    const errorFeedback = computed(() => {
      switch (error.value) {
        case 'already used':
          return contents.errors.already_used
        case 'invalid':
          return contents.errors.invalid
        default:
          return null
      }
    })

    const totalCost = computed(() => {
      return (contents.price.current * quantity.value)
        .toFixed(2)
        .replace('.', ',')
    })

    const oldTotalCost = computed(() => {
      return (contents.price.old * quantity.value).toFixed(2).replace('.', ',')
    })

    const onSubmit = values => {
      rangeFeedback.value = null
      if (!range.value.start && !range.value.end) {
        rangeFeedback.value = contents.errors.dates_required
        return
      }
      const totalTime = range.value.end.getTime() - range.value.start.getTime()
      const selectedDays = Math.ceil(totalTime / (1000 * 60 * 60 * 24))

      if (selectedDays > 30) {
        rangeFeedback.value = contents.errors.max_days
        return
      } else if (selectedDays < 1) {
        rangeFeedback.value = contents.errors.min_days
        return
      }

      if (
        hasRefNumber.value &&
        (!values.ref_number || values.ref_number === '')
      ) {
        refNumberFeedback.value = contents.errors.required
        return
      }

      const method = token ? 'travelUrl' : undefined
      setCheckoutData({
        surrounding_id: sid,
        first_name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        quantity: values.quantity,
        start_date: formatDate(range.value.start),
        end_date: formatDate(range.value.end),
        ref_number: values.ref_number,
        method,
        token,
      })
      if (method) {
        submitCheckout()
      } else {
        setCheckoutOpen()
      }
    }

    return {
      contents,
      schema,
      quantity,
      range,
      prefilled,
      hasRefNumber,
      rangeFeedback,
      refNumberFeedback,
      errorFeedback,
      totalCost,
      oldTotalCost,

      onSubmit,
      formatMarkup,
    }
  },
}
</script>
