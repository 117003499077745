import { createRouter, createWebHistory } from 'vue-router'
import General from '../views/General.vue'

const routes = [
  {
    path: '/',
    name: 'General',
    component: General,
  },
  {
    path: '/completed',
    name: 'Completed',
    component: () =>
      import(/* webpackChunkName: "completed" */ '../views/Completed.vue'),
  },
  {
    path: '/geen-toegang',
    name: 'NoAccess',
    component: () =>
      import(/* webpackChunkName: "noAccess" */ '../views/NoAccess.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () =>
      import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, _, next) => {
  if (to.name === 'General' && !to.query.sid) {
    next({ name: 'NoAccess' })
  }
  next()
})

export default router
