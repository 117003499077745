<template>
  <router-view />
  <transition name="fade">
    <div v-if="isLoading" class="loading-overlay">
      <div class="lds-ring">
        <div />
        <div />
        <div />
      </div>
    </div>
  </transition>
</template>

<script>
import { isLoading } from '@/repository'

export default {
  name: 'App',
  setup: () => ({ isLoading }),
}
</script>

<style lang="scss">
@import '@/assets/style/app.scss';
</style>
