<template>
  <div class="branding branding-custom" v-if="customBrand">
    <img class="branding-custom__logo" :src="getImage(customBrand)" />
    <div class="branding-custom__wait branding__divider" />
    <img
      class="branding-custom__wait branding__logo"
      src="@/assets/images/logo.svg"
    />
  </div>
  <div class="branding" v-else>
    <img class="branding__logo" src="@/assets/images/logo.svg" />
    <div class="branding__divider" />
    <p class="branding__slogan">Take your moment</p>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import customs from '../assets/customs.json'

export default {
  name: 'Branding',
  setup: () => {
    const route = useRoute()

    const customBrand = computed(() => {
      const id = Number(route.query.sid)
      let customBrand = null
      for (const custom of customs) {
        if (custom.ids.includes(id)) {
          if (custom.logo) {
            customBrand = custom.logo
          }
          break
        }
      }
      return customBrand
    })

    const getImage = customBrand => {
      return require(`@/assets/images/${customBrand}`)
    }

    return {
      customBrand,
      getImage,
    }
  },
}
</script>
