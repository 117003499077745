import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import * as yup from 'yup'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

yup.setLocale({
  mixed: {
    required: 'Dit veld is verplicht',
  },
  string: {
    max: 'Dit veld mag maximaal ${max} tekens bevatten',
    email: 'Dit veld moet een geldige e-mail zijn',
  },
  number: {
    min: 'Het aantal moet groter dan of gelijk zijn aan ${min}',
    max: 'Het aantal moet lager dan of gelijk zijn aan ${max}',
    integer: 'Het aantal moet een heel getal zijn',
  },
})

createApp(App)
  .use(router)
  .mount('#app')
